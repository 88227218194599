import { PerpsProvider } from '@kwenta/sdk/types'
import type { ReactNode } from 'react'

import PerennialIcon from 'assets/svg/partners/perennial.svg'
import SNXIcon from 'assets/svg/partners/snx.svg'
import ArbitrumIcon from 'assets/svg/providers/arbitrum.svg'
import BaseIcon from 'assets/svg/providers/base.svg'
import OptimismIcon from 'assets/svg/providers/optimism.svg'
import {
	DEFAULT_PERPS_PROVIDER,
	PERENNIAL_V2_ENABLED,
	SNX_V2_ENABLED,
	SNX_V3_ARB_ENABLED,
	SNX_V3_BASE_ENABLED,
} from 'constants/futures'
import { EXTERNAL_LINKS } from 'constants/links'
import ROUTES from 'constants/routes'
import type React from 'react'
import { HistoryTabs } from 'sections/dashboard/History/HistoryTabs'
import { MarketTabs } from 'sections/dashboard/Markets/MarketsTab'

export type Badge = {
	i18nLabel: string
	color: 'yellow' | 'red' | 'gray'
}

export type NetworkProvider = {
	name: PerpsProvider
	category: 'isolated-margin' | 'cross-margin'
	icon: React.ReactNode
	hasRewards?: boolean
	enabled: boolean
}

export type NetworkItem = {
	network: {
		icon: ReactNode
		name: string
	}
	providers: NetworkProvider[]
}

export type SubMenuLink = {
	i18nLabel: string
	link: string
	badge?: Badge[]
	icon?: ReactNode
	externalLink?: boolean
}

export const NavDropdowns = {
	Futures: 'futures',
} as const

export type NavDropdownType = (typeof NavDropdowns)[keyof typeof NavDropdowns]

type NavDropdown = {
	type: NavDropdownType
	items: NetworkItem[]
}

export type MenuLink = {
	i18nLabel: string
	link: string
	dropdown?: NavDropdown
	links?: SubMenuLink[] | null
	hidden?: boolean
}

export type MenuLinks = MenuLink[]

export const HOMEPAGE_MENU_LINKS: MenuLinks = [
	{
		i18nLabel: 'homepage.nav.markets',
		link: SNX_V3_BASE_ENABLED
			? ROUTES.Markets.Home(PerpsProvider.SNX_V3_BASE)
			: ROUTES.Markets.Home(PerpsProvider.SNX_V2_OP),
	},
	{
		i18nLabel: 'homepage.nav.stats',
		link: ROUTES.Stats.Home,
	},
	{
		i18nLabel: 'homepage.nav.governance.title',
		link: ROUTES.Home.Root,
	},
	{
		i18nLabel: 'homepage.nav.blog',
		link: EXTERNAL_LINKS.Social.Mirror,
	},
]

const DASHBOARD_LINKS = [
	{
		link: ROUTES.Dashboard.Home,
		i18nLabel: 'dashboard.tabs.overview',
	},
	{
		link: ROUTES.Dashboard.History(HistoryTabs.POSITIONS),
		i18nLabel: 'dashboard.tabs.history',
	},
	{
		link: ROUTES.Dashboard.Markets(MarketTabs.ALL_MARKETS),
		i18nLabel: 'dashboard.tabs.markets',
	},
	{
		link: ROUTES.Dashboard.Leaderboard,
		i18nLabel: 'dashboard.tabs.leaderboard',
	},
]

export const getMenuLinks = (isMobile: boolean): MenuLinks => [
	{
		i18nLabel: 'header.nav.dashboard',
		link: ROUTES.Dashboard.Home,
		links: isMobile ? DASHBOARD_LINKS : null,
	},
	{
		i18nLabel: 'header.nav.markets',
		link: ROUTES.Markets.Home(DEFAULT_PERPS_PROVIDER),
		dropdown: {
			type: NavDropdowns.Futures,
			items: [
				{
					network: {
						name: 'header.nav.dropdown.arbitrum',
						icon: <ArbitrumIcon width={17} height={17} />,
					},
					providers: [
						{
							name: PerpsProvider.PERENNIAL_V2_ARB,
							category: 'isolated-margin',
							icon: <PerennialIcon width={17} height={17} />,
							hasRewards: false,
							enabled: PERENNIAL_V2_ENABLED,
						},
						{
							name: PerpsProvider.SNX_V3_ARB,
							category: 'cross-margin',
							icon: <SNXIcon width={17} height={17} />,
							hasRewards: false,
							enabled: SNX_V3_ARB_ENABLED,
						},
					],
				},
				{
					network: {
						name: 'header.nav.dropdown.optimism',
						icon: <OptimismIcon width={17} height={17} />,
					},
					providers: [
						{
							name: PerpsProvider.SNX_V2_OP,
							category: 'isolated-margin',
							icon: <SNXIcon width={17} height={17} />,
							hasRewards: true,
							enabled: SNX_V2_ENABLED,
						},
					],
				},
				{
					network: {
						name: 'header.nav.dropdown.base',
						icon: <BaseIcon width={17} height={17} />,
					},
					providers: [
						{
							name: PerpsProvider.SNX_V3_BASE,
							category: 'cross-margin',
							icon: <SNXIcon width={17} height={17} />,
							enabled: SNX_V3_BASE_ENABLED,
						},
					],
				},
			],
		},
	},
	{
		i18nLabel: 'header.nav.staking',
		link: ROUTES.Staking.Home,
	},
	{
		i18nLabel: 'header.nav.rewards',
		link: ROUTES.Rewards.Home,
	},
	{
		i18nLabel: 'header.nav.swaps',
		link: ROUTES.Swaps.Home,
	},
]

export const DESKTOP_NAV_LINKS = getMenuLinks(false).filter((m) => !m.hidden)
export const MOBILE_NAV_LINKS = getMenuLinks(true).filter((m) => !m.hidden)

export const MENU_LINKS_WALLET_CONNECTED: MenuLinks = []
