import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwitchChain } from 'wagmi'

import Connector from 'containers/Connector'

const useNetworkSwitcher = (network = 10) => {
	const { isWalletConnected } = Connector.useContainer()
	const { openConnectModal } = useConnectModal()
	const [, setNetworkError] = useState<string | null>(null)
	const { switchChain } = useSwitchChain()

	const { t } = useTranslation()

	const switchToL2 = async () => {
		if (!isWalletConnected) {
			openConnectModal?.()
			return
		}
		try {
			if (!switchChain) {
				return setNetworkError(t('user-menu.error.please-install-metamask'))
			}
			setNetworkError(null)
			switchChain({ chainId: network })
		} catch (e) {
			setNetworkError(e.message)
		}
	}

	return {
		switchToL2,
	}
}

export default useNetworkSwitcher
