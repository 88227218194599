import styled from 'styled-components'

type ColorVariant = 'primary' | 'secondary' | 'tertiary'

type InputTitleProps = {
	margin?: string
	variant?: ColorVariant
}

type InputTitleSpanProps = {
	invalid?: boolean
	variant?: ColorVariant
}

const InputTitle = styled.div<InputTitleProps>`
	color: ${(props) => props.theme.colors.selectedTheme.input.label[props.variant || 'secondary']};
	font-size: 12px;
	margin: ${(props) => props.margin || '0'};
`

export const InputTitleSpan = styled.span<InputTitleSpanProps>`
	font-size: 12px;
	color: ${(props) =>
		props.invalid
			? props.theme.colors.selectedTheme.red
			: props.theme.colors.selectedTheme.input.label[props.variant || 'secondary']};
`

export default InputTitle
